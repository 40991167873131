import { useState, useEffect } from 'react';
import { baseUrl } from '../auth/drupal';

const useGetCropData = () => {
  const [sourceCropData, setCropData] = useState([]);

  useEffect(() => {
      const getCropData = async () => {
        try {
          const res = await fetch(
            `/page-data/hero_images.json`
          );

          if (res.ok) {
            const data = await res.json();
            if (data && data.length) {
              setCropData(() => [...data]);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };

      getCropData();
  }, []);
  
  return { sourceCropData };
};

export default useGetCropData;
