import { useState, useEffect } from 'react';
import { baseUrl } from '../auth/drupal';

/**
 * Hook for fetching landingPageData by their ids. Will also fetch their images.
 * Will filter out failed fetches.
 * @param {[strings]} landingPageId
 * @returns {[{landingPageData}, boolean, string]}
 */
const useGetLandingPage = (landingPageId = '') => {
  const [landingPage, setLandingPage] = useState(landingPageId);
  const [landingPageData, setLandingPageData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [message, setMessage] = useState(`Loading...`);

  useEffect(() => {
    if (landingPage) {
      const getLandingPageData = async () => {
        try {
          const res = await fetch(
            `${baseUrl}/jsonapi/landing_page/${landingPage}`
          );

          if (res.ok) {
            const data = await res.json();
            setLandingPageData(() => [...data]);
          }

          setLoadingData(false);
        } catch (error) {
          console.log(error);
          setMessage(
            `There was an error loading the landing page data. Please reload the page. 
            If this issue persists then please get in contact with the owner of this website.`
          );
          setLoadingData(false);
        }
      };

      getLandingPageData();
    } else {
      setMessage(`There are currently no landing page data.`);
      setLoadingData(false);
    }
  }, [landingPage, setLandingPage]);

  return { setLandingPage, landingPageData, loadingData, message };
};

export default useGetLandingPage;
