import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import ImageInView from '../../fx/animate/ImageInView';
import { getImageSizeWithCMS } from '../../helper/imageStyleSizes';
import { useWindowResize } from '../../..//util/hooks/useWindowResize';
import useGetCropData from '../../../util/hooks/useGetCropData';

const HeroHalfImage = (props) => {
  const { data, path, title, device, crop } = props;

  const [image, setImage] = React.useState(null);
  const [d, setData] = React.useState(null);
  const [t, setTitle] = React.useState(null);
  const [cropData, setCropData] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false);
  
  React.useEffect(() => {
    if (typeof window === null) return;

    const banner = data?.relationships?.field_landing_page_banner?.image_style_uri.find((i) => i.gatsby_landing_page_banner);
    let img = data.relationships.field_artwork_images[0];
    if ( banner?.gatsby_landing_page_banner) {
      img = {
        uri: { url: banner?.gatsby_landing_page_banner.replace("http://18.219.219.206:8080", "https://cms.lochgallery.com") }
      }
    }

    img.uri.url = img.uri.url.startsWith('/sites') ? `https://cms.lochgallery.com${img.uri.url}` : img.uri.url;

    setImage(img)
    setTitle(title)
    setData(data);

    if (crop) {
      setCropData({...crop.node});
    }
  }, [])

  return (
    <div className="heroHalf_image">
      <div className="heroHalf_wrap">
        <Link to={d?.path?.alias || null} className={loaded ? 'loaded' : ''}>
          <ImageAsCropped
            img={image}
            crop={cropData}
            className="d-crop"
            style={{ maxHeight: '800px', width: '100%' }}
            onLoaded={() => setLoaded(true)}
          />
          <span className="heroHalf_artist">
            {d?.relationships?.field_artist_ref?.title}
          </span>
        </Link>
      </div>
      {path && t ? (
        <Link
          to={path}
          state={{ artist: d?.relationships?.field_artist_ref?.title }}
        >
          <span className="heroHalf_title h3">{t}</span>
        </Link>
      ) : null}
    </div>
  );
};

const HeroHome = ({
  dataOne,
  dataTwo,
  cropOne,
  cropTwo,
  titleOne,
  pathOne,
  titleTwo,
  pathTwo,
  device,
}) => {

  return (
  <React.Fragment>
    <div className="lt-hero-twoup-container">
      {dataOne ? (
        <div className={`lt-Left ${dataOne && dataTwo ? '' : 'lt-full'}`}>
          <HeroHalfImage
            data={dataOne}
            path={`/contemporary/`}
            title={`Contemporary`}
            device={device}
            crop={cropOne}
          />
        </div>
      ) : null}
      {dataTwo ? (
        <div className={`lt-Right ${dataOne && dataTwo ? '' : 'lt-full'}`}>
          <HeroHalfImage
            data={dataTwo}
            path={`/historical/`}
            title={`Historical`}
            device={device}
            crop={cropTwo}
          />
        </div>
      ) : null}
    </div>
  </React.Fragment>
)
};

export default HeroHome;

const ImageAsCropped = ({img, crop, style, onLoaded, ...rest}) => {
  const r = {...rest}
  const imgRef = React.useRef(null);
  const [naturalDimensions, setNaturalDimensions] = React.useState(null);
  const windowSize = useWindowResize();
  const { sourceCropData } = useGetCropData();
  const [imageUrl, setImageUrl] = React.useState(null);

  // get natural intrinsic image size
  React.useEffect(() => {
    // set crop
    const sourceCrop = sourceCropData.find((c) => crop?.entity_id === c?.entity_id);
    setNaturalDimensions({
      height: sourceCrop?.height,
      width: sourceCrop?.width
    })
    onLoaded();
  }, [])

  React.useEffect(() => {
    // set image url
    const url = img?.image_style_uri ? getImageSizeWithCMS(img, 'gatsby_landing_page_banner') : img?.uri?.url;
    setImageUrl(url);
  }, [img])

  let heightOfWindow = windowSize.height;
  let minHeight = 'calc(100vh - 200px)';

  if (windowSize.width > 1200 && windowSize.width / 2 < windowSize.height - 200) {
    heightOfWindow = (windowSize.width / 2) + 200;
    minHeight = '50vw';
  }
  if (windowSize.width < 1200) {
    heightOfWindow = (windowSize.width / 2) + 200;
    minHeight = '50vw';
  }

  let imageHeight = heightOfWindow - 200;

  if (heightOfWindow < (windowSize.width / 2)) {
    heightOfWindow = (windowSize.width / 2);
    minHeight = '50vw';
    imageHeight = heightOfWindow;
  }

  if (heightOfWindow > 1140) {
    heightOfWindow = 940;
    minHeight = '940px';
    imageHeight = heightOfWindow;
  }

  if (windowSize.width < 650) {
    heightOfWindow = windowSize.width;
  }

  if (windowSize.width < 550) {
    heightOfWindow = 350;
    minHeight = '350px';
    imageHeight = heightOfWindow;
  }

  // const heightOfWindow = windowSize.width > 1060 ? (windowSize.height * 1.1) :  windowSize.width / 2

  if (!naturalDimensions) {
    return <ImageInView 
      src={imageUrl}
      style={{ minHeight: heightOfWindow, ...style }}
    />
  }

  // get crop size percentage based on width
  const aspect = imageHeight / naturalDimensions.height;

  const width = naturalDimensions.width * aspect;
  const height = naturalDimensions.height * aspect;

  let orientation = 'center';
  if (crop?.y) {
    if (crop.y / naturalDimensions.height < 0.35) {
      orientation = 'top'
    }
    if (crop.y / naturalDimensions.height > 0.65) {
      orientation = 'bottom'
    }
  }

  return (
    <div className="cropped-image-wrapper"
      style={{
        height: `${imageHeight}px`,
        minHeight: minHeight,
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        border: '0'
      }}
    >
      <ImageInView 
        src={imageUrl}
        style={{
          height: imageHeight,
          width: '100%',
          position: 'absolute',
          objectFit: 'cover',
          objectPosition: orientation,
        }}
      />
      {/*
      <img ref={imgRef} src={img.uri.url} {...r} style={{
        height: imageHeight,
        width: '100%',
        position: 'absolute',
        objectFit: 'cover',
        objectPosition: orientation,
      }}/>
    */}
    </div>
  );
  }